<template>
    <div :class="[$store.state.layout_style, $store.state.menu_style]">
        <component v-bind:is="layout"></component>
    </div>
</template>
<script>
// layouts
import appLayout from './layouts/app-layout.vue';
import authLayout from './layouts/auth-layout.vue';

import '@/assets/sass/app.scss';

export default {
    metaInfo: {
        title: '首页',
        titleTemplate: '%s | ELMAN - ADMIN DASHBOARD'
    },
    components: {
        app: appLayout,
        auth: authLayout
    },
    computed: {
        layout() {
            return this.$store.getters.layout;
        }
    },
    data() {
        return {};
    },
    mounted() {
        
    },
    methods: {}
};
</script>

<style scoped>
    .feather-icon .icon-section { padding: 30px; }
    .feather-icon .icon-section h4 { color: #3b3f5c; font-size: 17px; font-weight: 600; margin: 0; margin-bottom: 16px; }
    .feather-icon .icon-content-container { padding: 0 16px; width: 96%; margin: 0 auto; border: 1px solid #bfc9d4; border-radius: 6px; }
    .feather-icon .icon-section p.fs-text { padding-bottom: 30px; margin-bottom: 30px; }
    .feather-icon .icon-container { cursor: pointer; }
    .feather-icon .icon-container svg { color: #3b3f5c; margin-right: 6px; vertical-align: middle; width: 19px; height: 19px; fill: rgba(0, 23, 55, 0.08); }
    .feather-icon .icon-container:hover svg { color: #4361ee; fill: rgba(27, 85, 226, 0.23921568627450981); }
    .feather-icon .icon-container span { display: none; }
    .feather-icon .icon-container:hover span { color: #4361ee; }
    .feather-icon .icon-link { color: #4361ee; font-weight: 600; font-size: 14px; }


    /*FAB*/
    .fontawesome .icon-section { padding: 30px; }
    .fontawesome .icon-section h4 { color: #3b3f5c; font-size: 17px; font-weight: 600; margin: 0; margin-bottom: 16px; }
    .fontawesome .icon-content-container { padding: 0 16px; width: 96%; margin: 0 auto; border: 1px solid #bfc9d4; border-radius: 6px; }
    .fontawesome .icon-section p.fs-text { padding-bottom: 30px; margin-bottom: 30px; }
    .fontawesome .icon-container { cursor: pointer; height: 20px; width: 20px; }
    .fontawesome .icon-container i { font-size: 20px; color: #3b3f5c; vertical-align: middle; margin-right: 10px; }
    .fontawesome .icon-container:hover i { color: #4361ee; }
    .fontawesome .icon-container span { color: #888ea8; display: none; }
    .fontawesome .icon-container:hover span { color: #4361ee; }
    .fontawesome .icon-link { color: #4361ee; font-weight: 600; font-size: 14px; }
</style>



