// 获取 localStorage ,增加到vue实例方便全局调用
export function localGet(key) {
    const value = window.localStorage.getItem(key)
    try {
        return JSON.parse(window.localStorage.getItem(key))
    } catch (error) {
        return value
    }
}

// 设置 localStorage ,增加到vue实例方便全局调用
export function localSet(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value))
}

//删除 某个 localStorage ,增加到vue实例方便全局调用
export function localRemove(key) {
    window.localStorage.removeItem(key)
}

//删除 所有 localStorage ,增加到vue实例方便全局调用
export function localClear() {
    window.localStorage.clear();
}