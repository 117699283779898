//封装请求的方法,
export function getUrlRules(url) {
    var url_rules = [
        '/auth-admin/login.html',
    ];
    for (var i in url_rules) {
        if (url_rules[i] == url) {
            return true;
        }
    }
    return false;
}

export function get(url, params = {}) {
    if (this.$getCookie('user_token') == null && url != '/admin/login/login') {
        this.$notify({ type: 'danger', message: '页面失效，请重新登录！' });
        return false
    }

    var res_headers = {};
    res_headers.token = this.$getCookie('user_token') ? this.$getCookie('user_token') : '';
    res_headers['Content-Type'] = 'application/json;charset=UTF-8';

    url = this.$url + url
    for (var i in params) {
        if (params[i] == "" && params[i] !== 0) {
            delete params[i];
        }
    }
    return new Promise((resolve, reject) => {
        this.$axios.get(url, {
            headers: res_headers,
            params: params,
            timeout: 30 * 1000
        }).then(response => {
            resolve(response.data)
        }).catch(err => {
            console.log(err);
            this.$notify({ type: 'danger', message: err });
            reject(err)
        })
    })
}

export function post(url, params = {}, responseType = '', contentType = 'application/json;charset=UTF-8') {
    if (this.$localGet('admin_token') == null && url != '/auth-admin/login.html') {
        this.$message({ message: '身份信息过期，需要您重新登录', type: 'warning' });
        this.$localRemove('admin_token');
        this.$router.push('/admin/login');
        return false;
    }

    var res_headers = {};
    if (this.$localGet('admin_token')) {
        res_headers['Authorization'] = `Bearer ${this.$localGet('admin_token')}`;
    }
    
    // if (url.indexOf('uploadFiles') >= 0) {
    //     res_headers['Content-Type'] = 'multipart/form-data';
    // } else {
    res_headers['Content-Type'] = contentType;
    // }

    for (var i in params) {
        if ((params[i] == "" && params[i] !== 0) || params[i] == null) {
            delete params[i];
        }
    }

    url = '/api' + url;

    return new Promise((resolve, reject) => {
        this.$axios.post(url, params, {
            headers: res_headers,
            responseType: responseType,
            timeout: 120 * 1000
        }).then(response => {
            resolve(response.data)
        }).catch(err => {
            if (err.response.data.code === 999) {
                this.$message({ message: '身份信息过期，需要您重新登录', type: 'warning' });
                this.$localRemove('admin_token');
                this.$router.push('/admin/login');
            } else {
                if (!err.response.data) {
                    this.$message({ message: '服务器异常', type: 'error' });
                    reject(err)
                } else {
                    reject(err.response.data)
                }
            }
        })
    })
}

