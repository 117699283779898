/**
 * 读取cookie，
 * 需要注意的是cookie是不能存中文的，如果需要存中文，解决方法是后端先进行编码encode()，
 * 前端取出来之后用decodeURI('string')解码。（安卓可以取中文cookie，IOS不行）
 * */
export const getCookie = (c_name) => {
    var name = c_name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) != -1) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

/**
 * 设置cookie
 * c_name: cookie的名字，
 * value : cookie值，
 * expiredays: 过期时间（天数）
 * */
export const setCookie = (c_name, value, expiredays) => {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + expiredays);
    document.cookie = c_name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString());
}


/**
 * 删除cookie
 * c_name: cookie的名字，
 * */
export const delCookie = (c_name) => {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = getCookie(c_name);
    if (cval != null)
        document.cookie = c_name + "=" + cval + ";expires=" + exp.toGMTString();
}
