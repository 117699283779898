<template>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <nav ref="menu" id="sidebar">
            <div class="shadow-bottom"></div>

            <perfect-scrollbar class="list-unstyled menu-categories" tag="ul" :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }">
                <div v-for="(item, key) in menu_list" :key="key">
                    

                    <router-link v-if="item.permission_status == 1 && (!item.children || item.children == '')" tag="li" :to="item.router_path" class="menu" @click.native="toggleMobileMenu">
                        <a class="dropdown-toggle">
                            <div class="">
                                <i :data-feather="item.permission_icon"></i><span class="icon-name"> {{ item.permission_name }}</span>
                            </div>
                        </a>
                    </router-link>



                    <li v-if="item.permission_status == 1 && (item.children != null && item.children != '')" class="menu">
                        <a :href="'#' + item.toggle_key" v-b-toggle class="dropdown-toggle" @click.prevent>
                            <div class="">
                                <i :data-feather="item.permission_icon"></i><span class="icon-name"> {{ item.permission_name }}</span>
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                            </div>
                        </a>
                        <b-collapse :id="item.toggle_key" accordion="menu">
                            <ul class="collapse submenu list-unstyled show" v-for="(value, keys) in item.children" :key="keys">
                                <router-link v-if="value.permission_status == 1" tag="li" :to="value.router_path" @click.native="toggleMobileMenu">
                                    <a>{{ value.permission_name }}</a>
                                </router-link>
                            </ul>
                        </b-collapse>
                    </li>

                </div>
            </perfect-scrollbar>
        </nav>
    </div>
    <!--  END SIDEBAR  -->
</template>
<script>
import '@/assets/sass/font-icons/fontawesome/css/regular.css';
import '@/assets/sass/font-icons/fontawesome/css/fontawesome.css';
import feather from 'feather-icons';
export default {
    data() {
        return { 
            user_info: {},
            menu_collapse: 'dashboard',
            menu_list: [],
            count:'',
        };
    },
    watch: {
        $route(to) {
            const selector = document.querySelector('#sidebar a[href="' + to.path + '"]');
            const ul = selector.closest('ul.collapse');
            if (!ul) {
                const ele = document.querySelector('.dropdown-toggle.not-collapsed');
                if (ele) {
                    ele.click();
                }
            }
        }
    },
    mounted() {
        
    },
    created () {
        this.user_info = this.$localGet('admin_info');
        
        // 获取菜单
        this.sidebarMenu();
    },
    methods: {
        async sidebarMenu() {
            await this.$post('/role/sidebar-permission.html').then(res => {
                if (res.code == 200) {
                    this.menu_list = res.data.sidebar;
                    this.$localSet('permission_info', res.data.permission);
                    // console.log(this.$localGet('permission_info'));
                }
            }).catch(err => {
                console.log(err)
                // this.$router.push('/admin/login');
            });


            // default menu selection on refresh
            const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
            if (selector) {
                const ul = selector.closest('ul.collapse');
                if (ul) {
                    let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
                    if (ele) {
                        ele = ele[0];
                        setTimeout(() => {
                            ele.click();
                        });
                    }
                } else {
                    selector.click();
                }
            }

            feather.replace();
        },
        toggleMobileMenu() {
            if (window.innerWidth < 991) {
                this.$store.commit('toggleSideBar', !this.$store.state.is_show_sidebar);
            }
        },
    }
};
</script>
