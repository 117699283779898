import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from '../views/home.vue';
import Home from '../views/enroll/list.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [
    //dashboard
    { 
        path: '/', name: 'Home', component: Home,
        meta: { layout: true, login: true }
    }, {
        path: '/index', name: 'Dashboard', component: () => import('@/views/home.vue'),
        meta: { layout: true, login: true }
    },
    

    // 报名信息管理
    {
        path: '/enroll/list',
        name: 'enroll_list',
        component: () => import('@/views/enroll/list.vue'),
        meta: { layout: true, login: true }
    }, {
        path: '/enroll/info',
        name: 'enroll_info',
        component: () => import('@/views/enroll/info.vue'),
        meta: { layout: true, login: true }
    }, 


    // 合同管理
    {
        path: '/contract/index/list',
        name: 'contract_list',
        component: () => import('@/views/contract/index/list.vue'),
        meta: { layout: true, login: true }
    }, {
        path: '/contract/index/info',
        name: 'contract_info',
        component: () => import('@/views/contract/index/info.vue'),
        meta: { layout: true, login: true }
    }, {
        path: '/contract/single/list',
        name: 'contract_single_list',
        component: () => import('@/views/contract/single/list.vue'),
        meta: { layout: true, login: true }
    },{
        path: '/contract/installment/list',
        name: 'installment_list',
        component: () => import('@/views/contract/installment/list.vue'),
        meta: { layout: true, login: true }
    }, 




    // 成绩单 证书
    {
        path: '/warehouse/list',
        name: 'warehouse-list',
        component: () => import('@/views/warehouse/list.vue'),
        meta: { layout: true, login: true }
    },
    // {
    //     path: '/template/school_list',
    //     name: 'school_template_list',
    //     component: () => import('@/views/template/schools/list.vue'),
    //     meta: { layout: true, login: true }
    // }, {
    //     path: '/template/certificate_template_list',
    //     name: 'certificate_template_list',
    //     component: () => import('@/views/template/certificate/list.vue'),
    //     meta: { layout: true, login: true }
    // }, {
    //     path: '/template/achievement_template_list',
    //     name: 'achievement_template_list',
    //     component: () => import('@/views/template/achievement/list.vue'),
    //     meta: { layout: true, login: true }
    // }, {
    //     path: '/template/create_certificate_achievement',
    //     name: 'create_certificate_achievement',
    //     component: () => import('@/views/template/create/info.vue'),
    //     meta: { layout: true, login: true }
    // },

    // 名片检索
    {
        path: '/business/list',
        name: 'business_list',
        component: () => import('@/views/business/list.vue'),
        meta: { layout: true, login: true }
    }, 

    // users 
    {
        path: '/users/student/list',
        name: 'student_list',
        component: () => import('@/views/users/student/list.vue'),
        meta: { layout: true, login: true }
    }, 
    {
        path: '/users/admin/list',
        name: 'admin_list',
        component: () => import('@/views/users/admin/list.vue'),
        meta: { layout: true, login: true }
    }, {
        path: '/users/dealer/list',
        name: 'dealer_list',
        component: () => import('@/views/users/dealer/list.vue'),
        meta: { layout: true, login: true }
    },


    // 学院管理
    {
        path: '/globe/college/list',
        name: 'college_list',
        component: () => import('@/views/globe/college/list.vue'),
        meta: { layout: true, login: true }
    }, {
        path: '/globe/college/info',
        name: 'college_info',
        component: () => import('@/views/globe/college/info.vue'),
        meta: { layout: true, login: true }
    },


    // blog 
    {
        path: '/globe/news/list',
        name: 'news_list',
        component: () => import('@/views/globe/news/list.vue'),
        meta: { layout: true, login: true }
    }, {
        path: '/globe/news/info',
        name: 'news_info',
        component: () => import('@/views/globe/news/info.vue'),
        meta: { layout: true, login: true }
    },

    
    // events 校友活动 
    {
        path: '/globe/events/list',
        name: 'events_list',
        component: () => import('@/views/globe/events/list.vue'),
        meta: { layout: true, login: true }
    }, {
        path: '/globe/events/info',
        name: 'events_info',
        component: () => import('@/views/globe/events/info.vue'),
        meta: { layout: true, login: true }
    },


    // permission 
    {
        path: '/permission/index',
        name: 'permission_index',
        component: () => import('@/views/permission/permission/index.vue'),
        meta: { layout: true, login: true }
    }, {
        path: '/role/index',
        name: 'role_index',
        component: () => import('@/views/permission/role/index.vue'),
        meta: { layout: true, login: true }
    }, {
        path: '/role/permission/setting',
        name: 'role_permission_setting',
        component: () => import('@/views/permission/role/permission/setting.vue'),
        meta: { layout: true, login: true }
    },

    // system
    {
        path: '/system/config/info',
        name: 'config_info',
        component: () => import('@/views/system/config/info.vue'),
        meta: { layout: true, login: true },
    }, {
        path: '/system/dealer/table',
        name: 'dealer_table',
        component: () => import('@/views/system/dealer/table.vue'),
        meta: { layout: true, login: true },
    },


    // login
    {
        path: '/admin/login',
        name: 'login',
        component: () => import('@/views/auth/login.vue'),
        meta: { layout: false, login: false }
    },

    
    {
        path: '/error404',
        name: '404',
        component: () => import('@/views/pages/error404.vue'),
        meta: { layout: false, login: false }
    }, 


    {
        path: '*',
        redirect: '/error404'
    }
];

const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
        store.commit('setLayout', 'auth');
    } else {
        store.commit('setLayout', 'app');
    }
    next(true);
});

export default router;
