<template>
    <div class="layout-px-spacing apps-invoice-list">

        <portal to="breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:;">报名信息</a></li>
                                <li class="breadcrumb-item active" aria-current="page"><span>待审核列表</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </portal>

        <div class="row layout-top-spacing">

            <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
                <div class="panel br-6">
                    <div class="custom-table">
                        <div class="table-header">
                            <div class="d-flex align-items-center flex-column flex-sm-row flex-shrink-0">
                                <div class="d-flex order-1 order-sm-0 align-items-center">
                                    <div class="d-flex">
                                        <b-dropdown ref="column_dropdown" :right="true" variant="outline-primary" class="custom-dropdown column-visibility mr-2 flex-shrink-0">
                                            <template #button-content>
                                                字段选项
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down" ><polyline points="6 9 12 15 18 9"></polyline></svg>
                                            </template>
                                            
                                            <b-checkbox v-for="(column, index) in columns" :key="index" v-model="column.is_visible" variant="primary" class="checkbox-outline-primary mx-3 my-2">
                                                {{ column.label }}
                                            </b-checkbox>
                                        </b-dropdown>
                                    </div>

                                    <div class="header-search">
                                        <b-input v-model="table_option.search_text" size="sm" placeholder="Search..." />
                                    </div>
                                    <span class="ml-2">
                                        <b-select v-model="table_option.status" size="sm">
                                            <b-select-option value="">全部</b-select-option>
                                            <b-select-option value="1">待审核</b-select-option>
                                            <b-select-option value="2">已通过</b-select-option>
                                            <b-select-option value="3">拒绝</b-select-option>
                                        </b-select>
                                    </span>
                                    <div class="mb-3 mb-sm-0 order-0 order-sm-1 mr-sm-2">
                                        <b-button variant="primary" class="ml-2" @click="search()">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                            搜索 / 加载
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- <div class="mb-3 mb-sm-0 order-0 order-sm-1 mr-sm-2">
                                <b-button variant="success" class="ml-2" @click="edit_user()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                    新增
                                </b-button>
                            </div> -->
                        </div>

                        <b-table ref="basic_table" responsive hover 
                            :items="itemsList" 
                            :fields="visible_fields" 
                            :per-page="table_option.page_size" 
                            :current-page="table_option.current_page"
                            sort-by="invoice" 
                            :show-empty="true">
                            
                            <template #cell(first_name)="row">
                                <div class="d-flex">
                                    <div class="usr-img-frame mr-2 rounded-circle">
                                        <img :src="'/api' + row.item.cover" class="img-fluid rounded-circle" alt="avatar" />
                                    </div>
                                    <p class="align-self-center mb-0 admin-name">{{row.item.first_name}}{{row.item.last_name}}</p>
                                </div>
                            </template>

                            <template #cell(telephone)="row">
                                <div class="d-flex">
                                    <p class="align-self-center mb-0 admin-name">{{row.item.phone_perfix}} {{row.item.telephone}}</p>
                                </div>
                            </template>

                            <template #cell(educational_photos)="row">
                                <div class="d-flex">
                                    <img :src="row.item.educational_photos[0].url" style="width:100px;height:50px" @click="openCoolLightBox(row)"/>
                                </div>
                            </template>

                            <template #cell(id_card_photo)="row">
                                <div class="d-flex">
                                    <img :src="row.item.id_card_photo[0].url" style="width:100px;height:50px" @click="openCoolLightBox(row)"/>
                                </div>
                            </template>

                            <template #cell(inch_photo)="row">
                                <div class="d-flex">
                                    <img :src="row.item.inch_photo[0].url" style="width:100px;height:50px" @click="openCoolLightBox(row)"/>
                                </div>
                            </template>

                            <template #cell(topping)="row">
                                <b-badge variant="info" class="mr-1" v-if="row.value == 1">是</b-badge>
                                <b-badge variant="danger" class="mr-1" v-else>否</b-badge>
                            </template>

                            <template #cell(enroll_status)="row">
                                <b-badge variant="info" class="mr-1" v-if="row.value == 1">待审核</b-badge>
                                <b-badge variant="success" class="mr-1" v-else-if="row.value == 2">审核通过</b-badge>
                                <b-badge variant="danger" class="mr-1" v-else>已拒绝</b-badge>
                            </template>


                            <template #cell(is_contract)="row">
                                <b-badge variant="info" class="mr-1" v-if="row.value == 1">已生成合同</b-badge>
                                <b-badge variant="danger" class="mr-1" v-else>未生成合同</b-badge>
                            </template>

                            <template #cell(action)="row">
                                <!-- 编辑详情 审核通过 驳回重审 生成合同  -->
                                <!-- <b-button variant="primary" class="mb-2" @click="edit_user(row.item)">详情</b-button> -->
                                <div class="position-relative">
                                    <b-dropdown :right="true" variant="icon-only" toggle-tag="a" class="custom-dropdown">
                                        <template #button-content>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                                        </template>
                                        <b-dropdown-item @click="reject_pass(row.item)" link-class="action-edit"  v-if="row.item.enroll_status == 2 && row.item.is_contract == 0">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
                                            生成合同
                                        </b-dropdown-item>
                                        <!-- <b-dropdown-item @click="contract(row.item)" link-class="action-edit"  v-if="row.item.enroll_status == 2 && row.item.is_contract == 1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
                                            查看合同
                                        </b-dropdown-item> -->
                                        <b-dropdown-item @click="edit_user(row.item)" link-class="action-edit">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-3"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg>
                                            查看｜编辑详情
                                        </b-dropdown-item>
                                        <b-dropdown-item link-class="action-edit" @click="delete_row(row.item, 2)" v-if="row.item.enroll_status == 1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevrons-up"><polyline points="17 11 12 6 7 11"></polyline><polyline points="17 18 12 13 7 18"></polyline></svg>
                                            审核通过
                                        </b-dropdown-item>
                                        <b-dropdown-item link-class="action-delete" @click="delete_row(row.item, 3)" v-if="row.item.enroll_status == 1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-octagon"><polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
                                            驳回重审
                                        </b-dropdown-item>
                                        <b-dropdown-item link-class="action-delete" @click="enroll_show(row.item)" v-if="row.item.enroll_status != 1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                                            删除报名信息
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </template>
                        </b-table>

                        <!-- foot page -->
                        <div class="table-footer">
                            <div class="dataTables_info">当前在第 {{ table_option.current_page ? table_option.current_page : 1 }} 页，共计 {{ itemsCount}} 条数据</div>
                            <div class="paginating-container pagination-solid flex-column align-items-right">
                                <b-pagination @change="bind_data()" v-model="table_option.current_page" :total-rows="itemsCount" :per-page="table_option.page_size" prev-text="Prev" next-text="Next" first-text="First" last-text="Last" first-class="first" prev-class="prev" next-class="next" last-class="last" class="rounded">
                                    <template #first-text>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" /></svg>
                                    </template>
                                    <template #prev-text>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" /></svg>
                                    </template>
                                    <template #next-text>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" /></svg>
                                    </template>
                                    <template #last-text>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7" /></svg>
                                    </template>
                                </b-pagination>
                            </div>
                        </div>
                        <!-- foot page end -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template> 

<script>
import '@/assets/sass/apps/contacts.scss';
import '@/assets/sass/apps/invoice-list.scss';
import '@/assets/sass/font-icons/fontawesome/css/regular.css';
import '@/assets/sass/font-icons/fontawesome/css/fontawesome.css';
import '@/assets/sass/components/custom-lightbox.scss';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
    metaInfo: { title: '报名列表' },
    data() {
        return {
            index: null,
            allControls: false, // all, minimal
            items: [],
            columns: [
                { key: 'id', label: '主键ID', stickyColumn:true, thStyle:"width:60px", is_visible: false},
                { key: 'first_name', label: '姓名', thStyle:"width:200px", is_visible: true},
                { key: 'last_name', label: '名字', thStyle:"width:200px", is_visible: false},
                { key: 'phone_perfix', label: '手机区号', thStyle:"width:200px", is_visible: false},
                { key: 'email', label: '邮箱', thStyle:"width:200px", is_visible: true},
                { key: 'telephone', label: '手机号', thStyle:"width:200px", is_visible: true},
                { key: 'id_card_number', label: '身份证号码', thStyle:"width:200px", is_visible: false},
                { key: 'country_name', label: '国家', thStyle:"width:200px", is_visible: false},
                { key: 'province_name', label: '省份', thStyle:"width:200px", is_visible: false},
                { key: 'city_name', label: '城市', thStyle:"width:200px", is_visible: false},
                { key: 'area_name', label: '乡镇', thStyle:"width:200px", is_visible: false},
                { key: 'address', label: '详细地址', thStyle:"width:200px", is_visible: false},

                { key: 'educational_background', label: '前置学历', thStyle:"width:180px", is_visible: false},
                { key: 'learning_type', label: '前置学校就读方式', thStyle:"width:100px", is_visible: false},
                { key: 'language_achievement', label: '语言成绩', thStyle:"width:180px", is_visible: false},
                { key: 'school_name', label: '前置学校名称', thStyle:"width:180px", is_visible: false},
                { key: 'school_address', label: '前置学校地址', thStyle:"width:100px", is_visible: false},
                { key: 'educational_photos', label: '前置学历照片', thStyle:"width:180px", is_visible: false},

                { key: 'id_card_photo', label: '身份证照片', thStyle:"width:180px", is_visible: false},
                { key: 'inch_photo', label: '一寸照片', thStyle:"width:100px", is_visible: false},

                { key: 'school_name', label: '选择院校', thStyle:"width:180px", is_visible: true},
                { key: 'major_name', label: '专业', thStyle:"width:100px", is_visible: true},
                { key: 'degree_name', label: '学位', thStyle:"width:100px", is_visible: true},
                { key: 'admission_time', label: '入学时间', thStyle:"width:120px", is_visible: true},
                { key: 'graduation_time', label: '毕业时间', thStyle:"width:120px", is_visible: true},
                { key: 'reading_method', label: '就读方式', thStyle:"width:100px", is_visible: false},

                { key: 'is_contract', label: '生成合同', thStyle:"width:80px", is_visible: true},
                { key: 'enroll_status', label: '审核状态', thStyle:"width:80px", is_visible: true},
                { key: 'action', label: '操作', thStyle:"width:150px", is_visible: true},
            ],
            itemsList: [], 
            itemsCount: 0,

            table_option: { 
                current_page: 1, 
                page_size: 20, 
                search_text: '',
                status: "1", 
            },
        }
    },
    computed: {
        visible_fields() {
            return this.columns.filter(field => field.is_visible);
        },
    },
    mounted() {
        // 用户信息
        
        // 初始化加载
        this.bind_data();
    },
    methods: {
        bind_data() {
            this.$post('/enroll/get-list.html', this.table_option).then(res => {
            	if (res.code == 200) {
					let common_table_info = [];
					for(let i in res.data.list){
						common_table_info.push(res.data.list[i]);
					}
					this.itemsList = common_table_info;
					this.itemsCount = res.data.total;
            	} else {
            		this.$notify({ title: '信息', message: res.msg, type: 'info'});
            	}
            }).catch(err => {
                console.log(err);
                this.$notify({ title: '错误', message: err.msg, type: 'error'});
            });
        },
        search() {
            this.table_option.current_page = 1;
            this.table_option.page_size = 20;
            this.bind_data();
        },
        edit_user(item) {
            let routers = {path: '/enroll/info', query: {}};
            if (item) {
                routers.query.unique = item.id;
            } 
            this.$router.push(routers)
        },
        contract(item) {

        },
        enroll_show(item) {
            let _this = this;

            this.$swal({
                text: "确定要删除该客户的报名信息吗？",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '确认',
                padding: '2em'
            }).then(result => {
                if (result.value) {
                    this.$post('/enroll/delete-enroll.html', {unique: item.id}).then(res => {
                        if (res.code == 200) {
                            // 重新加载页面
                            _this.bind_data();

                            _this.$notify({title: '消息', message: '操作成功！', type:'success'});
                        } else {
                            this.$notify({ title: '信息', message: res.msg, type: 'info'});
                        }
                    })
                }
            })
        },
        reject_pass(item) {
            let _this = this;

            this.$swal({
                text: "确定要生成合同吗？",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '确认',
                padding: '2em'
            }).then(result => {
                if (result.value) {
                    let routers = {path: '/contract/index/info', query: {
                        type: 'insert',
                    }};
                    if (item) {
                        routers.query.unique = item.id;
                    }
                    _this.$router.push(routers);
                }
            })
        },
        delete_row(item, status) {
            if (!item || !item.id) {
                this.$notify({title: '错误', message: '缺失重要参数，请刷新页面后重试！', type:'info'});
                return false;
            } 
            let _this = this;

            this.$swal({
                text: "确定要进行本次操作吗!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '确认',
                padding: '2em'
            }).then(result => {
                if (result.value) {
                    _this.$post('/enroll/reject-pass.html', { unique: item.id, status: status}).then(res => {
                        if (res.code == 200) {
                            // 重新加载页面
                            _this.bind_data();

                            _this.$notify({title: '消息', message: '操作成功！', type:'success'});
                        } else {
                            _this.$notify({title: '消息', message: '操作失败，请刷新页面后重试！', type:'info'});
                        }
                        console.log(res);
                    }).catch(err => {
                        console.log(err);
                        _this.$notify({ title: '错误', message: '初始化失败', type: 'error'});
                    });
                }
            })
        },
        openCoolLightBox(row){
            this.items = [{ src: row.value, thumb: row.value, title: row.item.college_name, description: row.item.college_introduction }]; 
            this.index = row.index;
        },
    }
};
</script>
